<template>
  <v-app id="inspire">
    <v-main>
      <v-container>
        <v-row>
          <v-col
            md="6"
            offset-md="3"
          >
            <v-card>
              <v-system-bar
                window
                color="indigo darken-2"
                dark
              >
                <span>电子保单查询</span>
                <v-spacer></v-spacer>
              </v-system-bar>
              <v-toolbar
                dense
                color="indigo"
                dark
              >
                <v-text-field
                  hide-details
                  single-line
                  label="输入投保人(家长)的身份证号"
                  v-model="cardNo"
                ></v-text-field>
                <v-btn
                  icon
                  @click="searchOrder"
                >
                  <v-icon> search </v-icon>
                </v-btn>
              </v-toolbar>

              <v-data-table
                :headers="headers"
                :items="orders.Payload"
                :server-items-length="orders.Total"
                :loading="loading"
                item-key="id"
                class="dt"
                hide-default-footer
                dense
              >
                <template v-slot:item.eorderurl="props">
                  <a
                    :href="props.item.eorderurl"
                    target="_blank"
                  >打开</a>
                </template>

                <template v-slot:item.order_time="props">
                  {{ props.item.order_time | moment("YYYY-MM-DD") }}
                </template>
                <template v-slot:no-data>没有保单被找到</template>
              </v-data-table>
              <div class="text-xs-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pages"
                  :total-visible="5"
                ></v-pagination>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <p class="red--text text-caption">提醒:<br />
              1.建议电子保单获取后保存到手机方便查看，避免重复查询造成保单获取失败。<br />
              2.支付成功后系统获取电子保单链接可能存在延时，未查询到保单或保单下载失败时可以通过短信链接或人保官网 (www.picc.com) 查询电子保单。</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="msg.open"
      :bottom="!msg.top"
      :color="msg.color || 'success'"
      :left="msg.left"
      :multi-line="msg.ml"
      :right="msg.right"
      :timeout="msg.timeout || 5000"
      :top="msg.top"
      :vertical="false"
    >
      {{ msg.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          x-small
          text
          v-bind="attrs"
          @click="msg.open = false"
        > x </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    cardNo: "",
    orders: [],
    msg: {
      open: false,
      timeout: 5000,
      color: "success",
      left: false,
      right: false,
      ml: false,
      top: true,
      text: "",
    },
    pagination: {
      page: 1,
      rowsPerPage: 20,
    },
    headers: [
      {
        text: "投保人",
        value: "buyer",
        align: "center",
        sortable: false,
        class: "data-table-header blue lighten-3",
      },
      {
        text: "投保时间",
        value: "order_time",
        align: "center",
        sortable: false,
        class: "data-table-header blue lighten-3",
      },
      {
        text: "电子保单",
        value: "eorderurl",
        align: "center",
        sortable: false,
        class: "data-table-header blue lighten-3",
      },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        this.searchOrder();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (this.orders.Total) {
        return Math.ceil(this.orders.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    searchOrder() {
      if (!this.cardNo) {
        return;
      }
      this.$hc
        .req()
        .get(
          `orders/eorders?p=${this.pagination.rowsPerPage}&i=${this.pagination.page}&c=${this.cardNo}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.orders = x.Data;
          },
          (x) => {}
        );
    },
  },
};
</script>
