<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>省市区保单饼图</v-toolbar-title>
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-card-subtitle>
        <v-container fluid elevation-3>
          <v-layout grid-list-md>
            <v-flex xs2>
              <v-menu
                ref="startDialog"
                v-model="startDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchModel.From"
                    label="开始日期"
                    readonly
                    dense
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchModel.From"
                  no-title
                  @input="startDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="endDialog"
                v-model="endDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchModel.To"
                    label="结束日期"
                    readonly
                    dense
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchModel.To"
                  no-title
                  @input="endDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout grid-list-md>
            <v-flex xs2>
              <v-select
                :items="regions"
                label="省"
                item-text="name"
                v-model="region.province"
                return-object
                dense
                @change="provinceChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.province.childs"
                label="市"
                item-text="name"
                v-model="region.city"
                return-object
                dense
                @change="cityChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.city.childs"
                label="县/区"
                item-text="name"
                v-model="region.region"
                return-object
                dense
              ></v-select>
            </v-flex>

            <v-flex xs8>
              <div style="margin: 0 0 0 15px">
                <v-btn small @click="resetSearch">重置</v-btn>
                <v-btn
                  small
                  class="primary"
                  style="margin: 0 0 0 15px"
                  @click="searchOrder"
                  >确定</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-subtitle>
      <v-card-text>
        <v-chart class="chart" :option="chartOption" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);
export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      region: {
        province: {
          childs: [],
        },
        childs: [],
        city: {
          childs: [],
        },
        region: { childs: [] },
      },
      loading: false,
      startDialog: false,
      endDialog: false,
      searchModel: {},
      regions: [],
      orderData: [],
      headers: [
        {
          text: "位置",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3 nowrap",
        },
        {
          text: "数量",
          value: "value",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
      chartOption: {
        title: {
          text: "区域-保单数",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          type: "scroll",
          data: [],
        },
        series: [
          {
            name: "区域-保单数",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchRegions();
  },
  methods: {
    provinceChanged() {
      this.region.city = {
        childs: [],
      };
      this.region.region = {
        childs: [],
      };
      this.region.place = {
        childs: [],
      };
    },
    cityChanged() {
      this.region.region = {
        childs: [],
      };
      this.region.place = {
        childs: [],
      };
    },
    fetchRegions() {
      this.loading = true;
      this.$hc
        .req()
        .get(`channels/regions`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.regions = x.Data;
          },
          (x) => (this.loading = false)
        );
    },
    resetSearch() {
      this.region = {
        province: {
          childs: [],
        },
        childs: [],
        city: {
          childs: [],
        },
        region: { childs: [] },
      };
      this.orders = [];
      this.chartOption.legend.data = [];
      this.chartOption.series[0].data = [];
    },
    searchOrder() {
      var s = { ...this.searchModel };
      s.Province = this.region.province.name;
      s.City = this.region.city.name;
      s.Region = this.region.region.name;
      s.Type = "region";
      this.$hc
        .req()
        .post(`orderanalysis/regiondata`, s)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.orderData = x.Data;
            var total = 0;
            this.chartOption.legend.data = this.orderData.map((x) => {
              total += x.value;
              return x.name;
            });
            this.chartOption.series[0].data = this.orderData;
            this.chartOption.title.text = `时间段-保单数 (总${total}单)`;
          },
          (x) => {}
        );
    },
  },
};
</script>
<style scoped>
.chart {
  height: 500px;
}
.flex.xs2 {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
