<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>加密规则管理</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          style="margin-right: 10px; margin-left: 10px"
          color="primary"
          v-if="userinfo.userrole != 3"
          @click="openEditDialog(null)"
          >新建</v-btn
        >
        <v-btn color="success" @click="save()">保存</v-btn>
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data"
        disable-pagination
        :loading="loading"
        hide-default-footer
        item-key="name"
        :fixed-header="true"
      >
        <template v-slot:item.active="props">
          <v-switch v-model="props.item.active" dense></v-switch>
        </template>

        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="openEditDialog(props.item)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改规则</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="deleteRule(props.item.name)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除规则</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有规则被找到</template>
      </v-data-table>

      <v-dialog v-model="dialogChannel.open" persistent max-width="600px">
        <v-form
          ref="accountForm"
          v-model="dialogChannel.info.valid"
          lazy-validation
          autocomplete="random-string"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogChannel.info.title }}</span>
            </v-card-title>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="dialogChannel.info.data.name"
                    :rules="rules.name"
                    :counter="50"
                    maxlength="50"
                    label="名称"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogChannel.info.data.field"
                    :rules="rules.field"
                    :counter="50"
                    maxlength="50"
                    label="字段"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogChannel.info.data.rule"
                    :rules="rules.rule"
                    :counter="50"
                    maxlength="50"
                    label="规则"
                  ></v-text-field>
                  <v-switch
                    v-model="dialogChannel.info.data.active"
                    label="启用"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogChannel.open = false"
                >取消</v-btn
              >
              <v-btn
                color="warning"
                :disabled="!dialogChannel.info.valid"
                @click="createOrEditAccount(dialogChannel.info.data)"
                >确定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: true,
      dialogChannel: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      data: [],
      rules: {
        name: [
          (v) => !!v || "请填写名称",
          (v) => (v && v.length <= 50) || "名称长度必须少于50个字符",
        ],
        field: [
          (v) => !!v || "请填写字段",
          (v) => (v && v.length <= 50) || "字段长度必须少于50个字符",
        ],
        rule: [
          (v) => !!v || "请填写规则",
          (v) => (v && v.length <= 50) || "规则长度必须少于50个字符",
          (v) => !/[^*^A]+/.test(v) || "规则只允许字符'A'和'*'",
        ],
      },
      headers: [
        {
          text: "名称",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "字段",
          value: "field",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "规则",
          value: "rule",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "生效",
          value: "active",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
          width: "1%",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
    };
  },

  mounted() {
    this.fetch();
  },
  computed: {
    userinfo() {
      return this.$g.user || {};
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$hc
        .req()
        .get(`settings/encryptrule`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteRule(name) {
      this.$confirm("确定要删除这个规则吗? ").then((x) => {
        if (x) {
          var idx = -1;
          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].name == name) {
              idx = i;
              break;
            }
          }
          if (idx >= 0) {
            this.data.splice(idx, 1);
          }
        }
      });
    },
    openEditDialog(data) {
      if (data == null) {
        this.dialogChannel.info.title = "新建加密规则";
        this.dialogChannel.info.data = {};
        this.dialogChannel.open = true;
      } else {
        this.dialogChannel.info.title = "修改加密规则";
        this.dialogChannel.info.data = { ...data };
        this.dialogChannel.open = true;
      }
    },

    createOrEditAccount(data) {
      if (!this.$refs.accountForm.validate()) return;
      var idx = -1;
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i].name == data.name) {
          idx = i;
          break;
        }
      }
      if (idx >= 0) {
        this.data[idx].rule = data.rule;
        this.data[idx].field = data.field;
        this.data[idx].active = data.active;
      } else {
        this.data.unshift({
          name: data.name,
          rule: data.rule,
          field: data.field,
          active: data.active,
        });
      }
      this.dialogChannel.open = false;
    },
    save() {
      this.$hc
        .req()
        .put(`settings/encryptrule`, this.data)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.fetch();
            this.dialogChannel.open = false;
          },
          (x) => {}
        );
    },
  },
};
</script>
