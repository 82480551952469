import Vue from "vue";
import Router from "vue-router";
import Accounts from "../views/Accounts.vue";
import Channels from "../views/Channels.vue";
import AccountChannels from "../views/AccountChannels.vue";
import ChannelRedirectRules from "../views/ChannelRedirectRules.vue";
import EncryptionRules from "../views/EncryptionRules.vue";
import Orders from "../views/Orders.vue";
import RegionDataPie from "../views/OrderData/RegionDataPie.vue";
import AgeDataPie from "../views/OrderData/AgeDataPie.vue";
import TimeRangeDataPie from "../views/OrderData/TimeRangePie.vue";
import RegionDataSummary from "../views/OrderData/RegionDataSummary.vue";
import CallbackLogs from "../views/CallbackLogs.vue";
import EOrderQuery from "../views/EOrderQuery.vue";
import App from "../App.vue";
import InCompletedOrders from "../views/IncompletedOrders.vue";

Vue.use(Router);
var r = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/eorders",
      name: "eorders",
      component: EOrderQuery,
    },
    {
      path: "/",
      name: "admin_area",
      component: App,
      children: [
        {
          path: "/",
          name: "orders",
          component: Orders,
        },
        {
          path: "/accounts",
          name: "Accounts",
          component: Accounts,
        },
        {
          path: "/channels",
          name: "channels",
          component: Channels,
        },
        {
          path: "/accounts/:id/channels",
          name: "accountchannels",
          component: AccountChannels,
          props: true,
        },
        {
          path: "/settings/channelredirectrules",
          name: "settings_channelredirectrules",
          component: ChannelRedirectRules,
        },
        {
          path: "/settings/encryptionrules",
          name: "settings_encryptionrules",
          component: EncryptionRules,
        },
        {
          path: "/orderanalysis/regionpie",
          name: "orderanalysis_regionpie",
          component: RegionDataPie,
        },
        {
          path: "/orderanalysis/agepie",
          name: "orderanalysis_agepie",
          component: AgeDataPie,
        },
        {
          path: "/orderanalysis/timerangepie",
          name: "orderanalysis_timerangepie",
          component: TimeRangeDataPie,
        },
        {
          path: "/orderanalysis/ordersummary",
          name: "orderanalysis_ordersummary",
          component: RegionDataSummary,
        },
        {
          path: "/callbacklogs",
          name: "callbacklogs",
          component: CallbackLogs,
        },
        {
          path: "/incompletedorders",
          name: "incompletedorders",
          component: InCompletedOrders,
        },
      ],
    },
  ],
});

r.beforeEach(async (to, from, next) => {
  if (to.name == "eorders") {
    next();
    return;
  }
  if (!Vue["g"].enums) {
    var a = await Vue["hc"]
      .req()
      .get(`settings/enums`)
      .go({
        lock: true,
        toastError: false,
        toastSuccess: "",
      })
      .toPromise();
    Vue["g"].enums = a.Data;
  }

  next();
});
export default r;
