import Vue from 'vue'
import 'babel-polyfill'
import Landing from './views/Landing.vue'
import vuetify from './plugins/vuetify'
import router from './routes'
import VueResource from 'vue-resource'
import VueLocalStorage from 'vue-localstorage'
import VuetifyConfirm from 'vuetify-confirm'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import notificationPlugin from './plugins/notification'
import httpClientPlugin from './plugins/httpclient'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import './assets/main.css'



Vue.config.productionTip = false
Object.defineProperty(TiptapVuetifyPlugin, 'vuetifyLang', { get: function () { return 'zh' } })
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
});
//https://github.com/yariksav/vuetify-confirm
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: '确定',
  buttonFalseText: '取消',
  color: 'warning',
  icon: 'warning',
  title: '注意!',
  width: 350,
  property: '$confirm'
})

//https://github.com/brockpetrie/vue-moment
const moment = require('moment')
require('moment/locale/zh-cn')
window.m = moment
window.v=Vue;
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(notificationPlugin)
Vue.use(VueResource)
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true
})

Vue.http.options.root = process.env.VUE_APP_API;
Vue.http.interceptors.push(function (r) {
  r.headers.set('Content-Type', 'application/json');
  r.headers.set('Accept', 'application/json')
  const tk = Vue.ls.get('token', null, String);
  if (tk) {
    try {
      let u = JSON.parse(tk);
      r.headers.set('Authorization', `Bearer ${u.Token}`)
    }
    catch (ex) {
    }
  }
})
Vue.use(httpClientPlugin)
const requireComponent = require.context(
  './components',
  true,
  /[A-Z]\w+\.vue$/
)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName.replace(/.+\/([^/]*)\.vue$/, '$1')
    )
  )
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})
//Vue.component('vue-tribute', VueTribute)
new Vue({
  vuetify,
  router,
  render: h => h(Landing)
}).$mount('#app')
