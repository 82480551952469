<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>渠道码管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="搜索渠道码"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="userinfo.userrole != 3" @click="openEditDialog(null)"
          >新建渠道码</v-btn
        >
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
      >
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="deleteUser(props.item.id)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除渠道码</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                small
                icon
                v-on="on"
                @click="openEditDialog(props.item)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改渠道码</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有渠道码被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>

      <v-dialog v-model="dialogChannel.open" persistent max-width="600px">
        <v-form
          ref="accountForm"
          v-model="dialogChannel.info.valid"
          lazy-validation
          autocomplete="random-string"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogChannel.info.title }}</span>
            </v-card-title>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.number"
                    :rules="rules.number"
                    :counter="50"
                    maxlength="50"
                    label="编号"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.code"
                    :rules="rules.code"
                    :counter="50"
                    label="渠道码"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.province"
                    :counter="50"
                    maxlength="50"
                    label="省"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.city"
                    :counter="50"
                    maxlength="50"
                    label="市"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.region"
                    :counter="50"
                    maxlength="50"
                    label="地区"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.place"
                    :counter="50"
                    maxlength="50"
                    label="接种点"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="dialogChannel.info.data.name"
                    :counter="50"
                    maxlength="50"
                    label="渠道"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="dialogChannel.info.data.note"
                    :counter="200"
                    maxlength="200"
                    label="备注"
                    rows="4"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogChannel.open = false">取消</v-btn>
              <v-btn
                color="warning"
                :disabled="!dialogChannel.info.valid"
                @click="createOrEditAccount(dialogChannel.info.data)"
                >确定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      dialogChannel: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      data: {},
      rules: {
        province: [
          (v) => !!v || "请填写省",
          (v) => (v && v.length <= 50) || "省长度必须少于50个字符",
        ],
        city: [
          (v) => !!v || "请填写市",
          (v) => (v && v.length <= 50) || "市长度必须少于50个字符",
        ],
        region: [
          (v) => !!v || "请填写地区",
          (v) => (v && v.length <= 50) || "地区长度必须少于50个字符",
        ],
        place: [
          (v) => !!v || "请填写接种点",
          (v) => (v && v.length <= 50) || "接种点长度必须少于50个字符",
        ],
        number: [
          (v) => !!v || "请填写编号",
          (v) => (v && v.length <= 50) || "编号长度必须少于50个字符",
        ],
        code: [
          (v) => !!v || "请填写渠道码",
          (v) => (v && v.length <= 50) || "渠道码长度必须少于50个字符",
        ],
      },
      headers: [
        {
          text: "编号",
          value: "number",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "渠道码",
          value: "code",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "省",
          value: "province",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "市",
          value: "city",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "地区",
          value: "region",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "接种点",
          value: "place",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "渠道",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetch();
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    userinfo() {
      return this.$g.user || {};
    },
  },
  methods: {
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.find();
      }
    },
    find() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetch();
      }
    },
    fetch() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `channels?takeplace=no&pagesize=${this.pagination.rowsPerPage}&pageindex=${
            this.pagination.page
          }&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteUser(id) {
      this.$confirm("确定要删除这个渠道码吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`channels?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetch();
              },
              (x) => {}
            );
        }
      });
    },
    openEditDialog(data) {
      if (data) {
        this.dialogChannel.info.new = false;
        this.dialogChannel.info.title = "修改渠道码";
        this.dialogChannel.info.data = { ...data };
      } else {
        this.dialogChannel.info.new = true;
        this.dialogChannel.info.title = "新建渠道码";
        this.dialogChannel.info.data = {};
      }

      this.dialogChannel.open = true;
    },

    createOrEditAccount(data) {
      if (!this.$refs.accountForm.validate()) return;
      if (this.dialogChannel.info.new) {
        this.$hc
          .req()
          .post(`channels`, { ...data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetch();
              this.dialogChannel.open = false;
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .put(`channels`, { ...data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetch();
              this.dialogChannel.open = false;
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
