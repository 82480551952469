<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
    >
      <v-card>
        <v-card-title>
          <span class="headline">导入保单</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-file-input
                label="选择保单Excel"
                v-model="file"
                accept=".xlsx;.xls"
                :rules="file"
              ></v-file-input>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)"
            >取消</v-btn
          >
          <v-btn color="warning" :disabled="!valid" @click="importOrders()"
            >确定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderImportDialog",
  props: ["open"],
  data: () => ({
    file: null,
    valid: false,
    rules: {
      file: [(v) => !!v || "请选择保单excel"],
    },
  }),
  methods: {
    importOrders() {
      if (!this.$refs.form.validate()) return;
      var fe = new FormData();
      fe.append("files", this.file);
      this.$hc
        .req()
        .post(`orders/import`, fe)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作成功",
        })
        .subscribe(
          (x) => {
            this.$emit("success");
            this.$emit("update:open", false);
            this.file = null;
          },
          (x) => {}
        );
    },
  },
};
</script>
