<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title
          >用户[{{ data.account.name }}]可查看渠道码</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-autocomplete
          v-model="channel"
          :items="channels"
          :loading="isLoading"
          :search-input.sync="searchchannel"
          item-text="place"
          item-value="id"
          label="搜索渠道码"
          hide-details
          flat
          return-object
          :filter="itemFilter"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  >编号: {{ data.item.number }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  渠道码:{{ data.item.code }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ data.item.place }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-btn
          style="margin-right: 10px; margin-left: 10px"
          color="primary"
          @click="add()"
          >添加</v-btn
        >
        <v-btn color="success" @click="save()">保存</v-btn>
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.channels"
        disable-pagination
        :loading="tableLoading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
        :item-class="hightlightRow"
        dense
      >
        <template v-slot:item.op="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="deleteCode(props.item.code)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除渠道码</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有渠道码被找到</template>
      </v-data-table>
    </v-card>
  </div>
</template>


<script>
import { BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
export default {
  props: ["id"],
  data() {
    return {
      isLoading: false,
      tableLoading: true,
      searchchannel: null,
      obs: {
        search$: null,
        searchSub$: null,
      },
      data: {
        account: {},
        channels: [],
      },
      channel: null,
      channels: [],
      headers: [
        {
          text: "编号",
          value: "number",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "渠道码",
          value: "code",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "省",
          value: "province",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "市",
          value: "city",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "区县",
          value: "region",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "接种点",
          value: "place",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
    };
  },
  watch: {
    id: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
    searchchannel(val) {
      this.obs.search$.next(val);
    },
  },
  mounted() {
    this.fetch();
    this.obs.search$ = new BehaviorSubject();
    this.obs.searchSub$ = this.obs.search$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x && x.length >= 1),
        switchMap((x) => {
          this.isLoading = true;
          return this.$hc
            .req()
            .get(`channels?pagesize=10000&pageindex=1&q=${x || ""}`)
            .go({
              lock: false,
              toastError: true,
              toastSuccess: "",
            });
        })
      )
      .subscribe(
        (x) => {
          this.isLoading = false;
          this.channels = x.Data.Payload;
        },
        (x) => {
          this.isLoading = true;
        }
      );
  },
  computed: {
    userinfo() {
      return this.$g.user || {};
    },
  },
  methods: {
    itemFilter() {
      return true;
    },
    fetch() {
      this.tableLoading = true;
      this.$hc
        .req()
        .get(`accountchannels?accountid=${this.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.tableLoading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    hightlightRow(item) {
      if (item.accountid) {
        return "";
      } else {
        return "green lighten-3";
      }
    },
    deleteCode(code) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          var j = -1;
          for (var i = 0; i < this.data.channels.length; i++) {
            if (this.data.channels[i].code == code) {
              j = i;
              break;
            }
          }
          if (j >= 0) {
            this.data.channels.splice(j, 1);
          }
        }
      });
    },
    add() {
      if (this.channel) {
        var code = this.channel.code;
        for (var i = 0; i < this.data.channels.length; i++) {
          if (this.data.channels[i].code == code) {
            return;
          }
        }
        this.data.channels.unshift(this.channel);
      }
    },
    save() {
      this.tableLoading = true;
      var codes = [];
      for (var i = 0; i < this.data.channels.length; i++) {
        codes.push(this.data.channels[i].code);
      }
      this.$hc
        .req()
        .put(`accountchannels`, {
          accountid: this.id,
          codes: codes,
        })
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "成功",
        })
        .subscribe(
          (x) => {
            this.tableLoading = false;
            this.fetch();
          },
          (x) => {}
        );
    },
  },
};
</script>
