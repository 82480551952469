<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left dark class="indigo darken-4">
      <v-app-bar-nav-icon @click="drawerL = !drawerL"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>投保数据管理系统</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click.stop="logout">
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>登出系统</span>
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer v-model="drawerL" clipped app>
      <v-toolbar class="darken-3" v-if="userrole != ''">
        <v-toolbar-title> {{ username }}</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>
      <v-list>
        <v-list-item @click.stop to="/">
          <v-list-item-content>
            <v-list-item-title>保单数据</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop to="/orderanalysis/ordersummary">
          <v-list-item-content>
            <v-list-item-title>数据汇总</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-title>数据分析</v-list-item-title>
          </template>
          <v-list-item @click.stop to="/orderanalysis/regionpie">
            <v-list-item-content>
              <v-list-item-title>省市区</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/orderanalysis/agepie">
            <v-list-item-content>
              <v-list-item-title>年龄</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/orderanalysis/timerangepie">
            <v-list-item-content>
              <v-list-item-title>时间节点</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-title>系统管理</v-list-item-title>
          </template>
          <v-list-item @click.stop to="/accounts">
            <v-list-item-content>
              <v-list-item-title>用户管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/channels" v-if="userrole == 1 || userrole == 2">
            <v-list-item-content>
              <v-list-item-title>渠道码管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click.stop
            to="/settings/channelredirectrules"
            v-if="userrole == 1 || userrole == 2"
          >
            <v-list-item-content>
              <v-list-item-title>渠道码过滤</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/settings/encryptionrules" v-if="userrole == 1">
            <v-list-item-content>
              <v-list-item-title>加密规则</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/callbacklogs" v-if="userrole == 1">
            <v-list-item-content>
              <v-list-item-title>失败回调</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop to="/incompletedorders" v-if="userrole == 1">
            <v-list-item-content>
              <v-list-item-title>未完成订单</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="msg.open"
      :bottom="!msg.top"
      :color="msg.color || 'success'"
      :left="msg.left"
      :multi-line="msg.ml"
      :right="msg.right"
      :timeout="msg.timeout || 5000"
      :top="msg.top"
      :vertical="false"
    >
      {{ msg.text }}
      <template v-slot:action="{ attrs }">
        <v-btn x-small text v-bind="attrs" @click="msg.open = false"> x </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="this.ldSet.show" persistent max-width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>登录</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="loginForm"
            v-model="ldSet.vm.valid"
            lazy-validation
            dense
            autocomplete="random-string"
          >
            <v-text-field
              v-model="ldSet.vm.account"
              :rules="ldSet.rules.nameRules"
              prepend-icon="person"
              autocomplete="new-password"
              label="用户名"
              type="text"
              required
            ></v-text-field>
            <v-text-field
              v-model="ldSet.vm.password"
              autocomplete="new-password"
              :rules="ldSet.rules.passRules"
              prepend-icon="lock"
              label="密码"
              type="password"
              @keyup.enter.stop="login"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!ldSet.vm.valid" @click="login">登录</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer
      padless
      style="z-index:1000;"
    >
    <v-row
      justify="center"
      no-gutters
    >     
      <v-col
        class="py-2 text-center white--text text-caption"
        cols="12"
      >
        <a href="https://beian.miit.gov.cn/" style="color:#000000;text-decoration: none;" target="_blank">冀ICP备2021022542号</a>
      </v-col>
    </v-row>
  </v-footer>
  </v-app>
</template>

<script>
import moment from "moment";
export default {
  name: "App",

  data: () => ({
    ldSet: {
      show: false,
      rules: {
        nameRules: [(v) => !!v || "请填写用户名"],
        passRules: [(v) => !!v || "请填写密码"],
      },
      vm: {
        valid: false,
      },
    },
    username: "",
    userrole: "",
    obs: {
      search$: null,
      searchSub$: null,
    },
    drawerL: true,
    drawerR: false,
    isLoading: false,
    pages: [],
    page: null,
    search: null,
    loading: false,
    msg: {
      open: false,
      timeout: 5000,
      color: "success",
      left: false,
      right: false,
      ml: false,
      top: true,
      text: "",
    },
  }),
  watch: {
    search(val) {
      this.obs.search$.next(val);
    },
  },
  methods: {
    logout() {
      this.$ls.remove("token");
      this.ldSet.show = true;
    },

    login() {
      if (!this.$refs.loginForm.validate()) return;
      this.$hc
        .req()
        .post("accounts/login", {
          username: this.ldSet.vm.account,
          password: this.ldSet.vm.password,
        })
        .go({
          lock: true,
          toastError: false,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.$ls.set("token", JSON.stringify(x.Data));
            this.username = x.Data.UserName;
            this.userrole = x.Data.Role;
            this.$g.user = {
              username: x.Data.Name,
              userrole: x.Data.Role,
              userid: x.Data.MemberId,
            };
            if (x.Data.Role != 0) {
              this.$ns.cast("snack", {
                text: "登录成功",
                color: "success",
              });
              this.$router.go(0);
            } else {
              this.$ns.cast("snack", {
                text: "外部用户禁止登录",
                color: "error",
              });
            }
          },
          (x) => {
            this.$ns.cast("snack", {
              text: "用户名或密码错误",
              color: "error",
            });
          }
        );
    },
  },
  beforeMount() {
    const tk = this.$ls.get("token", null, String);
    this.$g.user = { username: "", userrole: 4, userid: 0 };
    if (tk) {
      try {
        let u = JSON.parse(tk);
        this.username = u.UserName;
        this.userrole = u.Role;
        this.$g.user = {
          username: u.Name,
          userrole: u.Role,
          userid: u.MemberId,
        };
        var now = moment();
        var issue = moment(u.IssueDate || "1900-01-01");
        var d = now.diff(issue, "minutes");
        if (d > (u.ExpiredIn || 720)) {
          this.ldSet.show = true;
        } else {
          this.ldSet.show = false;
        }
      } catch (ex) {
        this.ldSet.show = true;
      }
    } else {
      this.ldSet.show = true;
    }
    this.$ns.on("snack").subscribe((x) => {
      var opt = {
        open: false,
        timeout: 5000,
        color: "success",
        left: false,
        right: false,
        ml: false,
        top: true,
        text: "",
      };
      Object.assign(opt, x.data);
      this.msg = opt;
      this.msg.open = true;
    });
    this.$ns.on("lock").subscribe((x) => {
      this.loading = x.data;
    });
    this.$ns.on("login").subscribe(() => {
      if (!this.ldSet.show) {
        this.ldSet.show = true;
      }
    });
  },
};
</script>
