<template>
  <v-dialog v-model="open" persistent max-width="1100px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs2>
              <v-autocomplete
                v-model="data.selectedChannel"
                :items="data.channelsto"
                :loading="isLoading1"
                :search-input.sync="searchchannelto"
                item-text="code"
                item-value="code"
                label="渠道码"
                hide-details
                flat
                dense
                return-object
                :filter="itemFilter"
                @change="channelCodeChanged"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        >编号: {{ data.item.number }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        渠道码:{{ data.item.code }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ data.item.place }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete></v-flex
            >
            <v-flex xs2>
              <v-text-field
                dense
                v-model="data.province"
                label="省"
                :rules="rules.province"
              ></v-text-field>
            </v-flex>
            <v-flex xs2
              ><v-text-field
                dense
                v-model="data.city"
                label="市"
                :rules="rules.city"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                dense
                v-model="data.region"
                label="区县"
                :rules="rules.region"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                dense
                v-model="data.place"
                label="接种点"
                :rules="rules.place"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="data.inoculator"
                label="接种者姓名"
                :rules="rules.inoculator"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-select
                v-model="data.gender"
                :items="sextypes"
                :rules="rules.gender"
                item-text="name"
                item-value="field"
                label="性别"
                dense
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="birthdayDialog"
                v-model="birthdayDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedBirthday"
                    label="出生日期"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.birthday"
                  no-title
                  @input="birthdayDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                dense
                v-model="data.buyer"
                label="投保人"
                :rules="rules.buyer"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-select
                v-model="data.relationship"
                :items="relationtypes"
                :rules="rules.relationship"
                item-text="name"
                item-value="name"
                label="与接种者关系"
                dense
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                v-model="data.plan_code"
                :items="plantypes"
                :rules="rules.plan_code"
                item-text="name"
                item-value="field"
                label="产品方案"
                dense
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="data.payamount"
                label="金额"
                :rules="rules.payamount"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="odDialog"
                v-model="odDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="data.od"
                    label="购买日期"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.od"
                  no-title
                  @input="odDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="otDialog"
                v-model="otDialog"
                :return-value.sync="data.ot"
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.ot"
                    label="购买时间"
                    readonly
                    dense
                    :rules="rules.ot"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="data.ot"
                  v-if="otDialog"
                  @click:minute="$refs.otDialog.save(data.ot)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="startDateDialog"
                v-model="startDateDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedStartDate"
                    label="起保日期"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.start_time"
                  no-title
                  @input="startDateDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="endDateDialog"
                v-model="endDateDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedEndDate"
                    label="终保日期"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.end_time"
                  no-title
                  @input="endDateDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="data.order_no"
                label="订单号"
                :rules="rules.order_no"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="data.insurance_no"
                label="保单号"
                :rules="rules.insurance_no"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                dense
                v-model="data.eorderurl"
                label="电子保单地址"
                :rules="rules.eorderurl"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                dense
                v-model="data.card_no"
                label="身份证号"
                :rules="rules.card_no"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                dense
                v-model="data.phone"
                label="电话号码"
                :rules="rules.phone"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('update:open', false)"
            >取消</v-btn
          >
          <v-btn
            color="warning"
            :disabled="!valid"
            @click="createOrEditProject()"
            >确定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
export default {
  name: "OrderEditDialog",
  props: ["data", "open"],
  data: () => ({
    birthdayDialog: false,
    startDateDialog: false,
    endDateDialog: false,
    odDialog: false,
    otDialog: false,
    valid: false,

    isLoading1: false,
    startDialog: false,
    searchchannelto: null,
    obs: {
      searchto$: null,
      searchtoSub$: null,
    },
    rules: {
      province: [(v) => !!v || "请填写省"],
      city: [(v) => !!v || "请填写市"],
      region: [(v) => !!v || "请填写区县"],
      place: [(v) => !!v || "请填写接种点"],
      inoculator: [(v) => !!v || "请填写接种者姓名"],
      gender: [(v) => !!v || "请选择性别"],
      birthday: [(v) => !!v || "请选择出生日期"],
      buyer: [(v) => !!v || "请填写投保人"],
      relationship: [(v) => !!v || "请选择与接种者关系"],
      plan_code: [(v) => !!v || "请选择产品方案"],
      payamount: [(v) => (!!v && !isNaN(v * 1)) || "请填写金额"],
      start_time: [(v) => !!v || "请选择起保日期"],
      end_time: [(v) => !!v || "请选择终保日期"],
      od: [(v) => !!v || "请选择购买日期"],
      ot: [(v) => !!v || "请选择购买时间"],
      order_no: [(v) => !!v || "请填写订单号"],
      insurance_no: [(v) => !!v || "请填写保单号"],
      eorderurl: [(v) => !!v || "请填写电子保单地址"],
      card_no: [(v) => !!v || "请填写省份证号"],
      phone: [(v) => !!v || "请填电话号码"],
    },
  }),
  computed: {
    title() {
      if (this.data && this.data.id) {
        return "修改保单";
      }
      return "录入保单";
    },
    sextypes() {
      if (this.$g.enums && this.$g.enums.sextypes) {
        return this.$g.enums.sextypes;
      }
      return [];
    },
    plantypes() {
      if (this.$g.enums && this.$g.enums.plans) {
        return this.$g.enums.plans;
      }
      return [];
    },
    relationtypes() {
      if (this.$g.enums && this.$g.enums.relationtypes) {
        return this.$g.enums.relationtypes;
      }
      return [];
    },
    formatedBirthday() {
      return this.data && this.data.birthday
        ? this.$moment(this.data.birthday, "YYYY-MM-DDTHH:mm:ss").format(
            "YYYY-MM-DD"
          )
        : "";
    },
    formatedStartDate() {
      return this.data && this.data.start_time
        ? this.$moment(this.data.start_time, "YYYY-MM-DDTHH:mm:ss").format(
            "YYYY-MM-DD"
          )
        : "";
    },
    formatedEndDate() {
      return this.data && this.data.end_time
        ? this.$moment(this.data.end_time, "YYYY-MM-DDTHH:mm:ss").format(
            "YYYY-MM-DD"
          )
        : "";
    },
    formatedOrderDate() {
      return this.data && this.data.order_time
        ? this.$moment(this.data.order_time, "YYYY-MM-DDTHH:mm:ss").format(
            "YYYY-MM-DD"
          )
        : "";
    },
  },
  watch: {
    searchchannelto(val) {
      this.obs.searchto$.next(val);
    },
  },
  mounted() {
    this.obs.searchto$ = new BehaviorSubject();
    this.obs.searchtoSub$ = this.obs.searchto$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x && x.length >= 1),
        switchMap((x) => {
          this.isLoading1 = true;
          return this.$hc
            .req()
            .get(`channels?pagesize=100000&pageindex=1&q=${x || ""}`)
            .go({
              lock: false,
              toastError: true,
              toastSuccess: "",
            });
        })
      )
      .subscribe(
        (x) => {
          this.isLoading1 = false;
          this.data.channelsto = x.Data.Payload;
        },
        (x) => {
          this.isLoading1 = true;
        }
      );
  },
  methods: {
    itemFilter() {
      return true;
    },
    channelCodeChanged() {
      if (this.data.selectedChannel) {
        this.data.province = this.data.selectedChannel.province;
        this.data.city = this.data.selectedChannel.city;
        this.data.region = this.data.selectedChannel.region;
        this.data.place = this.data.selectedChannel.place;
      }
    },
    createOrEditProject() {
      if (!this.$refs.form.validate()) return;
      this.data.order_time = this.data.od + " " + this.data.ot;
      if (this.data.selectedChannel && this.data.selectedChannel.code) {
        this.data.channel_code = this.data.selectedChannel.code;
      }
      if (this.data.channelsto) {
        this.data.channelsto = [];
      }
      if (this.data && this.data.id) {
        this.$hc
          .req()
          .put(`orders`, { ...this.data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .post(`orders`, { ...this.data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.$emit("success");
              this.$emit("update:open", false);
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
