<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title> 保单数据</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="userinfo.userrole == 1"
          style="margin-right: 15px"
          class="success"
          @click="
            current = { selectedChannel: {} };
            dialog = true;
          "
          >手工录入保单</v-btn
        >
        <v-btn
          v-if="userinfo.userrole == 1 && 0"
          class="warning"
          @click="dialogImport = true"
          >导入Excel</v-btn
        >
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-card-subtitle>
        <v-container fluid>
          <v-layout grid-list-md>
            <v-flex xs2>
              <v-menu
                ref="startDialog"
                v-model="startDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchModel.From"
                    label="开始日期"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchModel.From"
                  no-title
                  @input="startDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="endDialog"
                v-model="endDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchModel.To"
                    label="结束日期"
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchModel.To"
                  no-title
                  @input="endDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                v-model="searchModel.Inoculator"
                dense
                label="接种者姓名"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="searchModel.Buyer"
                label="投保人姓名"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field dense v-model="searchModel.Phone" label="电话"></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="searchModel.Fuzzy"
                label="模糊查询"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout grid-list-md>
            <v-flex xs2>
              <v-select
                :items="regions"
                label="省"
                item-text="name"
                v-model="region.province"
                return-object
                dense
                @change="provinceChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.province.childs"
                label="市"
                item-text="name"
                v-model="region.city"
                return-object
                dense
                @change="cityChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.city.childs"
                label="县/区"
                item-text="name"
                v-model="region.region"
                return-object
                dense
                @change="regionChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.region.childs"
                label="接种点"
                item-text="name"
                v-model="region.place"
                return-object
                dense
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                dense
                v-model="searchModel.channel_name"
                label="渠道"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <div style="margin: 0 0 0 15px">
                <v-btn small @click="resetSearch">重置</v-btn>
                <v-btn
                  small
                  class="primary"
                  style="margin: 0 0 0 15px"
                  @click="clickSearch"
                  >搜索</v-btn
                >
                <v-btn small class="success" style="margin: 0 0 0 15px" @click="download"
                  >下载EXCEL</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orders.Payload"
          :server-items-length="orders.Total"
          :loading="loading"
          item-key="id"
          class="dt"
          hide-default-footer
          dense
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom v-if="userinfo.userrole == 1">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="orange"
                  small
                  icon
                  v-on="on"
                  @click="getModifyData(props.item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
              <span>修改保单</span>
            </v-tooltip>

            <v-tooltip bottom v-if="userinfo.userrole == 1">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  v-if="userinfo.userrole == 1"
                  @click.native.stop="deleteOrder(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除保单</span>
            </v-tooltip>
          </template>
          <template v-slot:item.eorderurl="props">
            <a :href="props.item.eorderurl" target="_blank" v-if="userinfo.userrole != 3"
              >打开</a
            >
          </template>
          <template v-slot:item.birthday="props">
            {{ props.item.birthday | moment("YYYY-MM-DD") }}
          </template>
          <template v-slot:item.order_time="props">
            {{ props.item.order_time | moment("YYYY-MM-DD HH:mm") }}
          </template>
          <template v-slot:no-data>没有保单被找到</template>
        </v-data-table>

        <v-card style="margin-top: 30px">
          <v-toolbar>
            <v-toolbar-title>共{{ orders.Total || 0 }}条</v-toolbar-title>
            <v-spacer></v-spacer>
            <div style="width: 250px">
              <v-select
                :items="pageOptions"
                hide-details
                label="每页显示记录数"
                v-model="pagination.rowsPerPage"
                item-text="name"
                item-value="value"
              ></v-select>
            </div>

            <v-spacer></v-spacer>
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
            ></v-pagination>
          </v-toolbar>
        </v-card>
      </v-card-text>
    </v-card>
    <order-edit-dialog
      :data="current"
      :open.sync="dialog"
      @success="searchOrder"
    ></order-edit-dialog>
    <order-import-dialog
      :open.sync="dialogImport"
      @success="searchOrder"
    ></order-import-dialog>
  </div>
</template>

<script>
import fileDownload from "js-file-download";
import Axios from "axios";
export default {
  data() {
    return {
      test: {},
      pageOptions: [
        { name: "50条/页", value: 50 },
        { name: "200条/页", value: 200 },
        { name: "500条/页", value: 500 },
        { name: "1000条/页", value: 1000 },
        { name: "1500条/页", value: 1500 },
        { name: "2000条/页", value: 2000 },
        { name: "全部", value: -1 },
      ],
      current: {},
      dialog: false,
      dialogImport: false,
      region: {
        province: {
          childs: [],
        },
        childs: [],
        city: {
          childs: [],
        },
        region: { childs: [] },
      },
      loading: false,
      startDialog: false,
      endDialog: false,
      searchModel: {},
      regions: [],
      orders: {},
      pagination: {
        page: 1,
        rowsPerPage: 50,
      },
      headers: [
        {
          text: "省",
          value: "province",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3 nowrap",
        },
        {
          text: "市",
          value: "city",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "区县",
          value: "region",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "接种点",
          value: "place",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "渠道码",
          value: "channel_code",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "渠道",
          value: "channel_name",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "接种者姓名",
          value: "inoculator",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "性别",
          value: "gender",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "出生日期",
          value: "birthday",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "投保时间",
          value: "order_time",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "投保人",
          value: "buyer",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "证件号",
          value: "card_no",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "电话",
          value: "phone",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "与接种者关系",
          value: "relationship",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "产品名称",
          value: "productname",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "产品方案",
          value: "plan",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "金额",
          value: "payamount",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },

        {
          text: "订单号",
          value: "order_no",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "保单号",
          value: "insurance_no",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "电子保单",
          value: "eorderurl",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },

        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        this.searchOrder();
      },
    },
    "pagination.rowsPerPage": {
      handler() {
        if (this.pagination.page == 1) {
          this.searchOrder();
        } else {
          this.pagination.page = 1;
        }
      },
    },
  },
  computed: {
    userinfo() {
      return this.$g.user || {};
    },
    pages() {
      if (this.orders.Total) {
        return Math.ceil(this.orders.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    apiroot() {
      return process.env.VUE_APP_API;
    },
  },
  mounted() {
    this.searchModel.From = this.$moment().format("YYYY-MM-DD");
    this.searchModel.To = this.$moment().format("YYYY-MM-DD");
    this.fetchRegions();
    this.searchOrder();
  },
  methods: {
    provinceChanged() {
      this.region.city = {
        childs: [],
      };
      this.region.region = {
        childs: [],
      };
      this.region.place = {
        childs: [],
      };
    },
    cityChanged() {
      this.region.region = {
        childs: [],
      };
      this.region.place = {
        childs: [],
      };
    },
    regionChanged() {
      this.region.place = {
        childs: [],
      };
    },

    fetchRegions() {
      this.loading = true;
      this.$hc
        .req()
        .get(`channels/regions`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.regions = x.Data;
          },
          (x) => (this.loading = false)
        );
    },
    resetSearch() {
      this.searchModel.From = "";
      this.searchModel.To = "";
      this.searchModel.channel_name = "";
      this.searchModel.Buyer = "";
      this.searchModel.Inoculator = "";
      this.searchModel.Phone = "";
      this.searchModel.Fuzzy = "";
      this.region = {
        province: {
          childs: [],
        },
        childs: [],
        city: {
          childs: [],
        },
        region: { childs: [] },
      };
      this.orders = [];
      this.pagination.page = 1;
    },
    getModifyData(order) {
      this.current = { selectedChannel: {}, ...order };
      if (this.current.order_time) {
        var d = this.$moment(this.current.order_time, "YYYY-MM-DDTHH:mm:ss");
        this.current.od = d.format("YYYY-MM-DD");
        this.current.ot = d.format("HH:mm");
      }
      if (order.channel_code) {
        this.current.selectedChannel = {
          id: 1,
          code: order.channel_code,
          province: "",
          city: "",
          region: "",
          place: order.channel_code,
        };
        this.current.channelsto = [
          {
            id: 1,
            code: order.channel_code,
            province: "",
            city: "",
            region: "",
            place: order.channel_code,
          },
        ];
      }
      this.dialog = true;
    },
    clickSearch() {
      if (this.pagination.page == 1) {
        this.searchOrder();
      } else {
        this.pagination.page = 1;
      }
    },
    searchOrder() {
      var s = { ...this.searchModel };
      s.Province = this.region.province.name;
      s.City = this.region.city.name;
      s.Region = this.region.region.name;
      if (this.region.place && this.region.place.name) {
        s.Place = this.region.place.name;
      }

      this.$hc
        .req()
        .post(
          `orders/search?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`,
          s
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.orders = x.Data;
          },
          (x) => {}
        );
    },
    download() {
      if (this.userinfo.userrole == 1) {
        var s = { ...this.searchModel };
        s.Province = this.region.province.name;
        s.City = this.region.city.name;
        s.Region = this.region.region.name;
        if (this.region.place && this.region.place.name) {
          s.Place = this.region.place.name;
        }
        this.$ns.cast("lock", true);
        this.$hc
          .req()
          .post(`orders/excelpre?pagesize=-1&pageindex=1`, s)
          .go({
            lock: false,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.$ns.cast("lock", false);
              if (x.Data.requiresecret) {
                var secret = window.prompt(`下载超过${x.Data.max}条记录需要输入下载密码`);
                var secretHash = this.$md5(secret);
                if (secretHash == x.Data.secret) {
                  this.downloadExcel(secret);
                } else {
                  window.alert("下载密码输入不正确");
                }
              } else {
                this.downloadExcel("");
              }
            },
            (x) => {}
          );
      } else {
        this.downloadExcel("");
      }
    },
    downloadExcel(secret) {
      this.$ns.cast("lock", true);
      var s = { ...this.searchModel };
      s.Province = this.region.province.name;
      s.City = this.region.city.name;
      s.Region = this.region.region.name;
      s.secret = secret;
      if (this.region.place && this.region.place.name) {
        s.Place = this.region.place.name;
      }
      const tk = this.$ls.get("token", null, String);
      let u = JSON.parse(tk);
      Axios({
        method: "post",
        url: `${this.apiroot}orders/excel?pagesize=-1&pageindex=1`,
        headers: {
          "Content-Type": "text/json",
          Authorization: `Bearer ${u.Token}`,
        },
        data: s,
        responseType: "blob",
      })
        .then((x) => {
          fileDownload(x.data, "orders.xlsx");
        })
        .finally(() => this.$ns.cast("lock", false));
    },
    deleteOrder(id) {
      this.$confirm("确定要删除这个保单吗?").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`orders?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.searchOrder();
              },
              (x) => {}
            );
        }
      });
    },
  },
};
</script>
<style scoped>
.dt >>> .text-center {
  white-space: nowrap;
}
.flex.xs2 {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
