import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

class Notification {
    constructor() {
        this._eventBus = new Subject();
        this.separator = ':';
    }


    cast(key, data = {}) {
        if (typeof key !== 'string' || !key.length) {
            throw new Error('Bad key. Please provide a string');
        }
        this._eventBus.next({ key, data });
    }


    on(key) {
        return this._eventBus.asObservable()
            .pipe(filter(event => this.keyMatch(event.key, key)))
            .pipe(map(event => {
                return {
                    key: event.key,
                    subKey: event.key.replace(key, ''),
                    data: event.data
                };
            }));
    }


    keyMatch(key, wildcard) {
        if (wildcard.endsWith(':')) {
            return key.startsWith(wildcard);
        } else {
            return key === wildcard;
        }
    }
}


export default new Notification()