<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>省市区保单数据汇总</v-toolbar-title>
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-card-subtitle>
        <v-container fluid elevation-3>
          <v-layout grid-list-md>
            <v-flex xs2>
              <v-menu
                ref="startDialog"
                v-model="startDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchModel.From"
                    label="开始日期"
                    readonly
                    dense
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchModel.From"
                  no-title
                  @input="startDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-menu
                ref="endDialog"
                v-model="endDialog"
                persistent
                dense
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchModel.To"
                    label="结束日期"
                    readonly
                    dense
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchModel.To"
                  no-title
                  @input="endDialog = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout grid-list-md>
            <v-flex xs2>
              <v-select
                :items="regions"
                label="省"
                item-text="name"
                v-model="region.province"
                return-object
                dense
                @change="provinceChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.province.childs"
                label="市"
                item-text="name"
                v-model="region.city"
                return-object
                dense
                @change="cityChanged"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-select
                :items="region.city.childs"
                label="县/区"
                item-text="name"
                v-model="region.region"
                return-object
                dense
              ></v-select>
            </v-flex>

            <v-flex xs8>
              <div style="margin: 0 0 0 15px">
                <v-btn small @click="resetSearch">重置</v-btn>
                <v-btn
                  small
                  class="primary"
                  style="margin: 0 0 0 15px"
                  @click="searchOrder"
                  >确定</v-btn
                >
                <v-btn small class="success" style="margin: 0 0 0 15px" @click="download"
                  >下载EXCEL</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orderData"
          disable-pagination
          :loading="loading"
          hide-default-footer
          :fixed-header="true"
          dense
        >
          <template v-slot:no-data>没有渠道码被找到</template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
import Axios from "axios";
export default {
  data() {
    return {
      region: {
        province: {
          childs: [],
        },
        childs: [],
        city: {
          childs: [],
        },
        region: { childs: [] },
      },
      loading: false,
      startDialog: false,
      endDialog: false,
      searchModel: {},
      regions: [],
      orderData: [],
      headers: [
        {
          text: "位置",
          value: "name",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3 nowrap",
        },
        {
          text: "数量",
          value: "value",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "总金额",
          value: "amount",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
    };
  },
  mounted() {
    this.fetchRegions();
  },
  computed: {
    apiroot() {
      return process.env.VUE_APP_API;
    },
  },
  methods: {
    provinceChanged() {
      this.region.city = {
        childs: [],
      };
      this.region.region = {
        childs: [],
      };
      this.region.place = {
        childs: [],
      };
    },
    cityChanged() {
      this.region.region = {
        childs: [],
      };
      this.region.place = {
        childs: [],
      };
    },
    fetchRegions() {
      this.loading = true;
      this.$hc
        .req()
        .get(`channels/regions`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.regions = x.Data;
          },
          (x) => (this.loading = false)
        );
    },
    resetSearch() {
      this.region = {
        province: {
          childs: [],
        },
        childs: [],
        city: {
          childs: [],
        },
        region: { childs: [] },
      };
      this.orders = [];
      this.chartOption.legend.data = [];
      this.chartOption.series[0].data = [];
    },
    searchOrder() {
      var s = { ...this.searchModel };
      s.Province = this.region.province.name;
      s.City = this.region.city.name;
      s.Region = this.region.region.name;
      s.Type = "region";
      this.$hc
        .req()
        .post(`orderanalysis/regiondata`, s)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            var totalCount = 0;
            var totalAmount = 0;
            if (x.Data && x.Data.length) {
              for (var i = 0; i < x.Data.length; i++) {
                totalAmount = totalAmount + x.Data[i].amount;
                totalCount = totalCount + x.Data[i].value;
              }
              x.Data.push({ name: "汇总", value: totalCount, amount: totalAmount });
            }
            this.orderData = x.Data;
          },
          (x) => {}
        );
    },
    download() {
      this.$ns.cast("lock", true);
      var s = { ...this.searchModel };
      s.Province = this.region.province.name;
      s.City = this.region.city.name;
      s.Region = this.region.region.name;
      s.Type = "region";
      const tk = this.$ls.get("token", null, String);
      let u = JSON.parse(tk);
      Axios({
        method: "post",
        url: `${this.apiroot}orderanalysis/excel`,
        headers: {
          "Content-Type": "text/json",
          Authorization: `Bearer ${u.Token}`,
        },
        data: s,
        responseType: "blob",
      })
        .then((x) => {
          fileDownload(x.data, "ordersummary.xlsx");
        })
        .finally(() => this.$ns.cast("lock", false));
    },
  },
};
</script>
<style scoped>
.chart {
  height: 500px;
}
.flex.xs2 {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
