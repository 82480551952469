<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>渠道码过滤规则管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="搜索规则"
          v-model="q"
          dense
          flat
          hide-details
          :append-icon="'search'"
          @keyup.enter.stop="search()"
          @click:append="search()"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          v-if="userinfo.userrole != 3"
          @click="openEditDialog(null)"
          >新建规则</v-btn
        >
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-data-table
        :headers="headers"
        :items="data.Payload"
        :server-items-length="data.Total"
        :loading="loading"
        hide-default-footer
        item-key="id"
        :fixed-header="true"
        dense
      >
        <template v-slot:item.rule="props">
          从编号{{ props.item.start }}到{{ props.item.end }}
        </template>
        <template v-slot:item.op="props">
          <v-tooltip bottom v-if="userinfo.userrole == 1">
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="openEditDialog(props.item)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>修改规则</span>
          </v-tooltip>
          <v-tooltip bottom v-if="userinfo.userrole == 1">
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                small
                icon
                v-on="on"
                @click.native.stop="deleteRule(props.item.id)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>删除规则</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>没有规则被找到</template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          :total-visible="10"
        ></v-pagination>
      </div>

      <v-dialog v-model="dialogChannel.open" persistent max-width="600px">
        <v-form
          ref="accountForm"
          v-model="dialogChannel.info.valid"
          lazy-validation
          autocomplete="random-string"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogChannel.info.title }}</span>
            </v-card-title>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.from"
                    :rules="rules.from"
                    label="起始编号(包含)"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="dialogChannel.info.data.to"
                    :rules="rules.to"
                    label="截止编号(包含)"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="dialogChannel.info.data.redirectcode"
                    :items="channelsto"
                    :loading="isLoading1"
                    :search-input.sync="searchchannelto"
                    item-text="place"
                    item-value="code"
                    label="重定向渠道码"
                    hide-details
                    :rules="rules.redirectcode"
                    flat
                    :filter="itemFilter"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            >编号: {{ data.item.number }}</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            渠道码:{{ data.item.code }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ data.item.place }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-model="dialogChannel.info.data.days"
                    :rules="rules.days"
                    label="天数"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogChannel.open = false"
                >取消</v-btn
              >
              <v-btn
                color="warning"
                :disabled="!dialogChannel.info.valid"
                @click="save(dialogChannel.info.data)"
                >确定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </div>
</template>


<script>
import { BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
export default {
  data() {
    return {
      loading: true,
      isLoading1: false,
      isLoading: false,
      searchchannel: null,
      searchchannelto: null,
      channels: [],
      channelsto: [],
      obs: {
        search$: null,
        searchSub$: null,
        searchto$: null,
        searchtoSub$: null,
      },
      q: "",
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      dialogChannel: {
        open: false,
        info: {
          valid: false,
          data: {},
        },
      },
      data: {},
      rules: {
        from: [
          (v) => !!v || "请填写起始编号",
          (v) => (v && v * 1 > 0) || "起始编号要大于0",
        ],
        to: [
          (v) => !!v || "请填写截止编号",
          (v) => (v && v * 1 > 0) || "截止编号要大于0",
        ],
        redirectcode: [(v) => !!v || "请选择新的渠道码"],
        days: [
          (v) => !!v || "请填写天数",
          (v) => (v && v * 1 > 0) || "天数需要大于0",
        ],
      },
      headers: [
        {
          text: "规则",
          value: "rule",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-2",
        },
        {
          text: "编号",
          value: "to_number",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-2",
        },
        {
          text: "渠道码",
          value: "to_code",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-2",
        },
        {
          text: "诊所",
          value: "to_name",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-2",
        },
        {
          text: "天数",
          value: "days",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-2",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-2",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
    searchchannelto(val) {
      this.obs.searchto$.next(val);
    },
  },
  mounted() {
    this.fetch();
    this.obs.searchto$ = new BehaviorSubject();
    this.obs.searchtoSub$ = this.obs.searchto$
      .asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x && x.length >= 1),
        switchMap((x) => {
          this.isLoading1 = true;
          return this.$hc
            .req()
            .get(`channels?pagesize=100000&pageindex=1&q=${x || ""}`)
            .go({
              lock: false,
              toastError: true,
              toastSuccess: "",
            });
        })
      )
      .subscribe(
        (x) => {
          this.isLoading1 = false;
          this.channelsto = x.Data.Payload;
        },
        (x) => {
          this.isLoading1 = true;
        }
      );
  },
  computed: {
    pages() {
      if (this.data.Total) {
        return Math.ceil(this.data.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
    userinfo() {
      return this.$g.user || {};
    },
  },
  methods: {
    itemFilter() {
      return true;
    },
    search() {
      if (this.pagination.page != 1) {
        this.pagination.page = 1;
      } else {
        this.fetch();
      }
    },
    fetch() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `channelredirectrules?pagesize=${
            this.pagination.rowsPerPage
          }&pageindex=${this.pagination.page}&q=${this.q || ""}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.data = x.Data;
          },
          (x) => {}
        );
    },
    deleteRule(id) {
      this.$confirm("确定要删除这个规则吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`channelredirectrules?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.fetch();
              },
              (x) => {}
            );
        }
      });
    },
    openEditDialog(data) {
      if (data) {
        this.channelsto = [
          {
            id: 1,
            code: data.to_code,
            province: "",
            city: "",
            region: "",
            place: data.to_code,
          },
        ];
        this.dialogChannel.info.new = false;
        this.dialogChannel.info.title = "修改渠道码";
        this.dialogChannel.info.data = {
          redirectcode: data.to_code,
          id: data.id,
          from: data.start,
          to: data.end,
          days: data.days,
        };
      } else {
        this.dialogChannel.info.new = true;
        this.dialogChannel.info.title = "新建渠道码";
        this.dialogChannel.info.data = {};
      }

      this.dialogChannel.open = true;
    },

    save(data) {
      if (!this.$refs.accountForm.validate()) return;
      if (this.dialogChannel.info.new) {
        this.$hc
          .req()
          .post(`channelredirectrules`, { ...data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetch();
              this.dialogChannel.open = false;
            },
            (x) => {}
          );
      } else {
        this.$hc
          .req()
          .put(`channelredirectrules`, { ...data })
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "操作成功",
          })
          .subscribe(
            (x) => {
              this.fetch();
              this.dialogChannel.open = false;
            },
            (x) => {}
          );
      }
    },
  },
};
</script>
