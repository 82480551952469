import notification from './notification'

export default {
    install: (Vue) => {
        var g = {};
        Vue['ns'] = notification;
        Vue.prototype.$ns = notification;
        window.$ns = notification;
        Vue['g'] = g;
        Vue.prototype.$g = g;
        window.$g = g;
    }
}