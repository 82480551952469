<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>失败回调</v-toolbar-title>
      </v-toolbar>
    </v-card>
    <v-card style="margin-top: 20px">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="callbacks.Payload"
          :server-items-length="callbacks.Total"
          :loading="loading"
          item-key="id"
          class="dt"
          hide-default-footer
          dense
        >
          <template v-slot:item.op="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="orange"
                  small
                  icon
                  v-on="on"
                  @click="retry(props.item.id)"
                >
                  <v-icon small>send</v-icon>
                </v-btn>
              </template>
              <span>重试</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  small
                  icon
                  v-on="on"
                  @click.native.stop="deleteCallback(props.item.id)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>没有失败回调</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      callbacks: {},
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3 nowrap",
        },
        {
          text: "类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "订单号",
          value: "orderno",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "时间",
          value: "created",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "类型",
          value: "type",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "错误信息",
          value: "errormessage",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
        {
          text: "操作",
          value: "op",
          align: "center",
          sortable: false,
          class: "data-table-header blue lighten-3",
        },
      ],
    };
  },
  mounted() {
    this.fetchCallbacks();
  },
  watch: {
    pagination: {
      handler() {
        this.fetchCallbacks();
      },
      deep: true,
    },
  },
  computed: {
    userinfo() {
      return this.$g.user || {};
    },
    pages() {
      if (this.callbacks.Total) {
        return Math.ceil(this.callbacks.Total / this.pagination.rowsPerPage);
      }
      return 0;
    },
  },
  methods: {
    fetchCallbacks() {
      this.loading = true;
      this.$hc
        .req()
        .get(
          `callbacklogs?pagesize=${this.pagination.rowsPerPage}&pageindex=${this.pagination.page}`
        )
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.callbacks = x.Data;
          },
          (x) => (this.loading = false)
        );
    },
    deleteCallback(id) {
      this.$confirm("确定要删除吗? 该操作无法恢复").then((x) => {
        if (x) {
          this.$hc
            .req()
            .delete(`callbacklogs?id=${id}`)
            .go({
              lock: true,
              toastError: true,
              toastSuccess: "操作成功",
            })
            .subscribe(
              (x) => {
                this.loading = false;
                this.fetchCallbacks();
              },
              (x) => (this.loading = false)
            );
        }
      });
    },
    retry(id) {
      this.$hc
        .req()
        .post(`callbacklogs?id=${id}`, {})
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "操作完成",
        })
        .subscribe(
          (x) => {
            this.loading = false;
            this.fetchCallbacks();
          },
          (x) => (this.loading = false)
        );
    },
  },
};
</script>
<style scoped>
.chart {
  height: 500px;
}
.flex.xs2 {
  padding-left: 5px;
  padding-right: 5px;
}
</style>